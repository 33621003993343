<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部投诉" name="first">
        <div class="Lists" v-for="(item,index) in List1" :key="index" @click="Go_Details(item.id)">
          <div class="Lists_Left">
            <img :src="item.evidential_materials.split(',')[0]" alt="">
          </div>
          <div class="Lists_Right">
            <div class="Lists_Right_top">
              <div class="Lists_Right_top_L">
                <img :src="item.headimgurl" alt="">
                <div class="name">{{item.nickname}}</div>
                <div class="data">{{item.create_time.slice(0,10)}}</div>
              </div>
              <div class="Lists_Right_top_R" v-if="item.status ==2">
                <span>已完成</span>
              </div>
              <div class="Lists_Right_top_R1" v-if="item.status ==5">
                <span>未通过</span>
              </div>
              <div class="Lists_Right_top_R2" v-if="item.status ==1">
                <span>处理中</span>
              </div>
              <div class="Lists_Right_top_R3" v-if="item.status ==0">
                <span>审核中</span>
              </div>
              <div class="Lists_Right_top_R4" v-if="item.status ==4">
                <span>已撤销</span>
              </div>
            </div>
            <div class="Lists_Right_title">
              {{item.statement_title}}
            </div>
            <div class="Lists_Right_content">
              <div class="Lists_Right_content_T">
                <span>[投诉对象]</span><span>{{item.complaintBusiness.name}}</span>
              </div>
              <div class="Lists_Right_content_B">
                <span>[投诉理由]</span><span>{{item.complaint_reason}}</span>
              </div>
            </div>
            <div class="Lists_Right_bottom">
              <div class="Lists_Right_bottom_L">
                <div>
                  <img src="@/assets/img/dianzan_icon.png" alt="">
                  <span>{{item.like_num}}</span>
                </div>
                <div>
                  <img src="@/assets/img/pinglun_icon.png" alt="">
                  <span>{{item.comment_num}}</span>
                </div>
              </div>
              <div class="Lists_Right_bottom_R">
                <div class="Immediate_evaluation1" @click.stop="Supplement(item)" v-if="item.status == 0">补充投诉</div>
                <div class="Immediate_evaluation1" @click.stop="Supplement(item)" v-if="item.status == 1">补充投诉</div>
                  <div class="Immediate_evaluation" v-if="item.evaluate_status==0&&item.status==2" @click.stop="ImmediateEvaluation(item.id,item.complaintBusiness.id)">立即评价</div>
                  <div class="Immediate_evaluationH" v-if="item.evaluate_status==1&&item.status==2" @click.stop>已评价</div>
                <div class="Immediate_evaluation1" v-if="item.status==5" @click.stop="WithdrawalComplaint(item.id)">撤销投诉</div>
                <div class="Immediate_evaluation1" v-if="item.status==1" @click.stop="WithdrawalComplaint(item.id)">撤销投诉</div>
                <div class="Immediate_evaluation1" v-if="item.status==0" @click.stop="WithdrawalComplaint(item.id)">撤销投诉</div>
                <div class="Immediate_evaluation" v-if="item.status==5" @click.stop="ReLaunch(item.id)">重新发起</div>
                <div class="Immediate_evaluation" v-if="item.status==4" @click.stop="ReLaunch(item.id)">重新发起</div>
                <div class="Immediate_evaluation" v-if="item.status==1" @click.stop="ConfirmCompletion(item.id)">确认完成

                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="处理中" name="second">
          <div class="Lists" v-for="(item,index) in List2" :key="index" @click="Go_Details(item.id)">
              <div class="Lists_Left">
                  <img :src="item.evidential_materials.split(',')[0]" alt="">
              </div>
              <div class="Lists_Right">
                  <div class="Lists_Right_top">
                      <div class="Lists_Right_top_L">
                          <img :src="item.headimgurl" alt="">
                          <div class="name">{{item.nickname}}</div>
                          <div class="data">{{item.create_time.slice(0,10)}}</div>
                      </div>
                      <div class="Lists_Right_top_R2" v-if="item.status ==1">
                          <span>处理中</span>
                      </div>
                  </div>
                  <div class="Lists_Right_title">
                      {{item.statement_title}}
            </div>
                  <div class="Lists_Right_content">
                      <div class="Lists_Right_content_T">
                          <span>[投诉对象]</span><span>{{item.complaintBusiness.name}}</span>
                      </div>
                      <div class="Lists_Right_content_B">
                          <span>[投诉理由]</span><span>{{item.complaint_reason}}</span>
                      </div>
                  </div>
                  <div class="Lists_Right_bottom">
                      <div class="Lists_Right_bottom_L">
                          <div>
                              <img src="@/assets/img/dianzan_icon.png" alt="">
                              <span>{{item.like_num}}</span>
                          </div>
                          <div>
                              <img src="@/assets/img/pinglun_icon.png" alt="">
                              <span>{{item.comment_num}}</span>
                          </div>
                      </div>
                      <div class="Lists_Right_bottom_R">
                          <div class="Immediate_evaluation1" @click.stop="Supplement(item)" v-if="item.status == 1">补充投诉</div>
                          <div class="Immediate_evaluation1" v-if="item.status==1" @click.stop="WithdrawalComplaint(item.id)">撤销投诉</div>
                          <div class="Immediate_evaluation" v-if="item.status==1" @click.stop="ConfirmCompletion(item.id)">确认完成</div>
                      </div>
                  </div>
              </div>
          </div>

     </el-tab-pane>
      <el-tab-pane label="已完成" name="third">
          <div class="Lists" v-for="(item,index) in List3" :key="index" @click="Go_Details(item.id)">
              <div class="Lists_Left">
                  <img :src="item.evidential_materials.split(',')[0]" alt="">
              </div>
              <div class="Lists_Right">
                  <div class="Lists_Right_top">
                      <div class="Lists_Right_top_L">
                          <img :src="item.headimgurl" alt="">
                          <div class="name">{{item.nickname}}</div>
                          <div class="data">{{item.create_time.slice(0,10)}}</div>
                      </div>
                      <div class="Lists_Right_top_R" v-if="item.status ==2">
                          <span>已完成</span>
                      </div>
                  </div>
                  <div class="Lists_Right_title">
                      {{item.statement_title}}
            </div>
                  <div class="Lists_Right_content">
                      <div class="Lists_Right_content_T">
                          <span>[投诉对象]</span><span>{{item.complaintBusiness.name}}</span>
                      </div>
                      <div class="Lists_Right_content_B">
                          <span>[投诉理由]</span><span>{{item.complaint_reason}}</span>
                      </div>
                  </div>
                  <div class="Lists_Right_bottom">
                      <div class="Lists_Right_bottom_L">
                          <div>
                              <img src="@/assets/img/dianzan_icon.png" alt="">
                              <span>{{item.like_num}}</span>
                          </div>
                          <div>
                              <img src="@/assets/img/pinglun_icon.png" alt="">
                              <span>{{item.comment_num}}</span>
                          </div>
                      </div>
                      <div class="Lists_Right_bottom_R">
                          <div class="Immediate_evaluation" v-if="item.evaluate_status==0&&item.status==2" @click.stop="ImmediateEvaluation(item.id,item.complaintBusiness.id)">立即评价</div>
                              <div class="Immediate_evaluationH" v-if="item.evaluate_status==1&&item.status==2" @click.stop>已评价</div>
                    </div>
                      </div>
                  </div>
              </div>


      </el-tab-pane>
      <el-tab-pane label="未通过" name="fourth">
          <div class="Lists" v-for="(item,index) in List4" :key="index" @click="Go_Details(item.id)">
              <div class="Lists_Left">
                  <img :src="item.evidential_materials.split(',')[0]" alt="">
              </div>
              <div class="Lists_Right">
                  <div class="Lists_Right_top">
                      <div class="Lists_Right_top_L">
                          <img :src="item.headimgurl" alt="">
                          <div class="name">{{item.nickname}}</div>
                          <div class="data">{{item.create_time.slice(0,10)}}</div>
                      </div>
                      <div class="Lists_Right_top_R1" v-if="item.status ==1">
                          <span>未通过</span>
                      </div>
                  </div>
                  <div class="Lists_Right_title">
                      {{item.statement_title}}
            </div>
                  <div class="Lists_Right_content">
                      <div class="Lists_Right_content_T">
                          <span>[投诉对象]</span><span>{{item.complaintBusiness.name}}</span>
                      </div>
                      <div class="Lists_Right_content_B">
                          <span>[投诉理由]</span><span>{{item.complaint_reason}}</span>
                      </div>
                  </div>
                  <div class="Lists_Right_bottom">
                      <div class="Lists_Right_bottom_L">
                          <div>
                              <img src="@/assets/img/dianzan_icon.png" alt="">
                              <span>{{item.like_num}}</span>
                          </div>
                          <div>
                              <img src="@/assets/img/pinglun_icon.png" alt="">
                              <span>{{item.comment_num}}</span>
                          </div>
                      </div>
                      <div class="Lists_Right_bottom_R">
                          <div class="Immediate_evaluation1" v-if="item.status==5" @click.stop="WithdrawalComplaint(item.id)">撤销投诉</div>
                          <div class="Immediate_evaluation" v-if="item.status==5" @click.stop="ReLaunch(item.id)">重新发起</div>
                      </div>
                  </div>
              </div>
          </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import { ComplaintUser_info, complaint_revoke,relaunch } from "../../api/api.js";
export default {
   metaInfo(){
     return{
        title: '我的投诉',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  inject:['reload'],
  data() {
    return {
      activeName: "first",
        List1:[],  //全部投诉
        List2:[],  //处理中
        List3:[],   // 已完成
        List4:[]    //未通过
    };
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    // 全部投诉
    ComplaintUser_info() {
      var that = this;
      ComplaintUser_info({ type: 0 }).then((res) => {
        console.log(res.data.data.data);
        that.List1 = res.data.data.data; //全部
      });
    },

      //处理中
      ComplaintUser_info_one() {
          var that = this;
          ComplaintUser_info({ type: 1 }).then((res) => {
              //console.log(res.data.data.data);
              that.List2 = res.data.data.data; //全部
          });
      },

      //已完成
      ComplaintUser_info_two() {
          var that = this;
          ComplaintUser_info({ type: 2 }).then((res) => {
              //console.log(res.data.data.data);
              that.List3 = res.data.data.data; //全部
          });
      },

      //未通过
      ComplaintUser_info_three() {
          var that = this;
          ComplaintUser_info({ type: 3 }).then((res) => {
              //console.log(res.data.data.data);
              that.List4 = res.data.data.data; //全部
          });
      },

    // 补充投诉
    Supplement(item) {
      //console.log(item);
      let JS_item=JSON.stringify(item);
        this.$router.push({
        path: "/PersonalCenter/SupplementaryComplaint",
        query:{item:JS_item}
      });
    },
    // 去详情页面
    Go_Details(id) {
      //console.log(id);
      this.$router.push({
        path: "/PersonalCenter/CDetails",
        query: { id: id },
      });
    },
    // 确认完成
    ConfirmCompletion(id) {
      this.$confirm("是否确认完成提交？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          complaint_revoke({ type: 2,complaint_id:id }).then((res) => {
            //console.log(res);
            this.$message({
              type: "success",
              message: "确认成功",
            });
            this.reload();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 撤销投诉
    WithdrawalComplaint(id){
      this.$confirm("是否确认撤销投诉？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          complaint_revoke({ type: 1,complaint_id:id }).then((res) => {
            //console.log(res);
            this.$message({
              type: "success",
              message: "撤销成功",
            });
            this.reload();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 重新发起
    ReLaunch(id){
      this.$confirm("是否重新发起投诉？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          relaunch({ complaint_id:id }).then((res) => {
            //console.log(res);
            this.$message({
              type: "success",
              message: "发起成功",
            });
            this.reload();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 立即评价
    ImmediateEvaluation(id,objid){
              this.$router.push({
        path: "/PersonalCenter/evaluate",
        query: { id: id,objid:objid },
      });
    },
  },
  mounted() {
    var that = this;
    that.ComplaintUser_info();
    that.ComplaintUser_info_one()
      that.ComplaintUser_info_two()
      that.ComplaintUser_info_three()
  },
};
</script>

<style scoped>
>>> .el-tabs {
  width: 960px;
  background: #fff;
  margin-left: 20px;
  padding: 30px 0px 40px 0px;
}
>>> .el-tabs__nav-wrap {
  padding: 0 2%;
}
/* >>> .el-tabs__content{
  width:92%;
  margin:0 auto;
  padding:0 20px;
  border-bottom:1px solid #e8e8e8;
} */
.Lists {
  width: 96%;
  /* width: calc(100% - 20px); */
  border-bottom: 1px solid #e8e8e8;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  padding: 0 0 20px 0;
  cursor: pointer;
}
.Lists:nth-child(1) {
  margin-top: 0;
}
.Lists_Left img {
  width: 208px;
  height: 204px;
  margin-right: 10px;
}
.Lists_Right_top {
  display: flex;
  width: 670px;
  justify-content: space-between;
}
.Lists_Right_top_L img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
}
.Lists_Right_top_L {
  display: flex;
  align-items: center;
}
.Lists_Right_top_L .name {
  margin-right: 25px;
  font-size: 18px;
  color: #333333;
}
.Lists_Right_top_L .data {
  color: #999999;
  font-size: 16px;
}
.Lists_Right_top_R {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #d2e3f7;
  border-radius: 4px;
  text-align: center;
}
.Lists_Right_top_R1 {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #cfcfcf;
  border-radius: 4px;
  text-align: center;
}
.Lists_Right_top_R2 {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #ffe9d3;
  border-radius: 4px;
  text-align: center;
  color: #fe9025;
}
.Lists_Right_top_R3 {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #d9f2ea;
  border-radius: 4px;
  text-align: center;
  color: #51c19a;
}
.Lists_Right_top_R4 {
  width: 60px;
  height: 22px;
  padding: 2px 7px;
  background: #cfcfcf;
  border-radius: 4px;
  color:#fff;
  text-align: center;
}
.Lists_Right_top_R span {
  color: #1f72d9;
}
.Lists_Right_title {
  width: 670px;
  font-size: 22px;
  color: #333333;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Lists_Right_content_T {
  margin-top: 15px;
  margin-bottom: 10px;
}
.Lists_Right_content_T span:nth-child(1) {
  color: #1f72d9;
  margin-right: 5px;
}
.Lists_Right_content_T span:nth-child(2) {
  color: #666666;
}
.Lists_Right_content_B {
  width: 470px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Lists_Right_content_B span:nth-child(1) {
  color: #1f72d9;
  margin-right: 5px;
}
.Lists_Right_content_B span:nth-child(2) {
  color: #666666;
}
.Lists_Right_bottom {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.Lists_Right_bottom_L {
  display: flex;
}
.Lists_Right_bottom_L div {
  display: flex;
  align-items: center;
  margin-right: 25px;
  color: #999999;
}
.Lists_Right_bottom_L img {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.Immediate_evaluation {
  width: 120px;
  height: 36px;
  border: 1px solid #1f72d9;
  border-radius: 18px;
  text-align: center;
  line-height: 36px;
  color: #1f72d9;
  cursor: pointer;
  margin-left: 20px;
}
.Immediate_evaluation1 {
  width: 120px;
  height: 36px;
  border: 1px solid #999999;
  border-radius: 18px;
  text-align: center;
  line-height: 36px;
  color: #333333;
  cursor: pointer;
  margin-left: 20px;
}
.Lists_Right_bottom_R {
  display: flex;
}

.Immediate_evaluationH {
    width: 120px;
    height: 36px;
    border-radius: 18px;
    text-align: center;
    background: #CFCFCF;
    line-height: 36px;
    color: #fff;
    cursor: pointer;
    margin-left: 20px;
}


</style>